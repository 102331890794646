<template>
  <div>
    <div id="placeholder"></div>
    <el-button @click='yulan'>预览</el-button>
  </div>
</template>

<script>
export default {
  data() {},
  mounted() {
    this.initEditor("","http://sdk.51changxie.com/demo/OEMContract.docx",true,true,true,true,"16d0538a-266a-4dab-afb9-bdfaeb5bb2d1","edit","",{"comment":true,"download":false,"edit":true,"print":false,"review":true})
  },
  methods: {
    yulan(){
      window.open("http://view.officeapps.live.com/op/view.aspx?src=https://www.kitozer.net:5055/%E5%8D%95%E8%81%94%E4%B8%80%E4%BD%93%E5%8C%96%E6%9C%BA%E6%9F%9C%E6%8A%A5%E4%BB%B7%E6%98%8E%E7%BB%86%E6%B8%85%E5%8D%9520201215.xls")
    },
    initEditor(username,docUrl, titlebarFlag, rightPanelFlag, leftPanelFlag, toobarFlag, docKey, mode, type, permission) {
	        //mode for editor

	        window.mode = window.mode || mode || "view";
	        mode = window.mode;

	        //mode for editor
	        window.type = window.type || type || "desktop";
	        type = window.type;

	        //url for document
	        // window.docUrl = document.getElementById("documentUrl").value;
	        window.docUrl = docUrl;
	        //key for chaching and collaborate editing
	        window.docKey = docKey;

	        //type for document
	        var docType = docUrl.substring(docUrl.lastIndexOf(".") + 1).trim().toLowerCase();
	        //type for editor
	        var documentType = this.getDocumentType(docType);


	        window.permission = permission;
	        window.user = username;

	        window.user = window.user || "测试"
	        var id = window.user + "-4"
	        //CXO_API.CXEditor
	        //creating object editing
	        var onDocumentReady = function() {
	            //editor.destroyEditor();

	        }
	        var onAppReady = function() {

	        }

	        var editor = new CXO_API.CXEditor("placeholder", {
	            type: type,
	            width: (type == "desktop" ? "1130px" : undefined),
	            height: (type == "desktop" ? "517px" : undefined),
	            documentType: documentType,
	            token: "eyJ0eXBlIjoiMSIsImFsZyI6IkhTNTEyIn0.e30.RgxbmTaMyvL4cYnOg2DH03JghyUeIlLTiFDZrxqM4Ai_8XGv0K1qw0DB2r9EDRmiLb9ROxzzg36J62V-2IvaXA",
	            document: {
	                title: docUrl,
	                url: docUrl,
	                fileType: docType,
	                key: docKey + 2,
	                permissions: window.permission
	            },
	            editorConfig: {
	                mode: mode,
	                user: {
	                    "id": id,
	                    "name": window.user
	                },
	                customization: {
	                    about: true,
	                    chat: false,
	                    comments: true,
	                    zoom: 50,
	                    leftMenu: leftPanelFlag,
	                    rightMenu: rightPanelFlag,
			    toolbar: toobarFlag,
			    displayTitle:titlebarFlag,
	                    header: true,
	                    statusBar: true,
			    autosave: false,
		            forcesave: false
                    },
	            },
	            events: {
	                'onDocumentReady': onDocumentReady,
	                'onAppReady': onAppReady
	            }
	        });
	    },

     key(k) {
	        // var result = k.replace(new RegExp("[^0-9-.a-zA-Z_=]", "g"), "_") + (new Date()).getTime();
	        var result = k.replace(new RegExp("[^0-9-.a-zA-Z_=]", "g"), "_");
	        return result.substring(result.length - Math.min(result.length, 20));
	    },
     getDocumentType(ext) {
	        if (".doc.docx.docm.dot.dotx.dotm.odt.fodt.ott.rtf.txt.html.htm.mht.pdf.djvu.fb2.epub.xps".indexOf(ext) != -1) return "text";
	        if (".xls.xlsx.xlsm.xlt.xltx.xltm.ods.fods.ots.csv".indexOf(ext) != -1) return "spreadsheet";
	        if (".pps.ppsx.ppsm.ppt.pptx.pptm.pot.potx.potm.odp.fodp.otp".indexOf(ext) != -1) return "presentation";
	        return null;
	    },
  },
};
</script>

<style>
</style>